import request from '../axios'

export const getAllData = () => request({
  url: 'http://36.147.92.54:8090/import/getAll',
  method: 'post',
});

export const uploadData = (data) => request({
  url: 'http://36.147.92.54:8090/import/uploadData',
  method: 'post',
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  data
});
