import { url } from '@/config';
import request from '../axios'


export const login = (username,password) => request({
  url: url + '/user/login',
  method: 'post',
  params: {
    username: username,
    password: password
  }
});
