<template>
  <div style="padding: 5%">
    <el-card class="box-card" style="background-color: #2b2b2c;">
      <el-form ref="form" :model="form" label-width="180px">
        <el-form-item label="选择表">
          <el-select v-model="form.id" placeholder="请选择表">
            <el-option v-for="item in tableOptions" :key="item.id" :label="item.table_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-for="item in tableOptions" :key="item.id">
          <el-link type="primary" :href="item.address" @ size="small">下载{{item.table_name}}模板</el-link>
        </el-form-item>
        <el-form-item label="上传导入表">
          <!--          <el-upload
              class="upload-demo"
              ref="upload"
              multiple
              accept=".xlsx"
              action=""
              :with-credentials="true"
              :http-request="httpRequest"
              :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          </el-upload>-->
          <el-upload ref="upload" class="upload-demo" action="" accept=".xlsx" :http-request="handleUploadForm"
            :auto-upload="false" multiple :limit="1" :on-exceed="handleExceed">
            <el-link icon="el-icon-paperclip" type="primary">选择文件</el-link>
          </el-upload>
          <el-button @click="onSubmit()">上传</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" style="float: right" @click="onSubmit">提 交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { getAllData, uploadData } from "@/api/exportData";
export default {
  data () {
    return {
      tableOptions: [],
      form: {
        id: '',
        name: '',
        address: ''
      }
    }
  },
  created () {
    this.getToken()
  },
  mounted() {
    this.getAll()
  },
  methods: {
    clean(){
      localStorage.removeItem('token')
    },
    getToken(){
      const token = localStorage.getItem('token')
      if(token === 'export' || token === 'admin'){
        console.log('信息校验正确')
      } else {
        this.$router.push('/login')
      }
    },
    handleExceed () { },
    async getAll () {
      const res = await getAllData()
      this.tableOptions = res.data
      this.form.id = res.data[0].id
    },
    async httpRequest (param) {
      console.log('进入上传方法')
      let formData = new FormData()
      formData.append('id', this.form.id)
      formData.append('filePath', param.file)
      const res = await uploadData(formData)
      console.log(res)
    },
    async handleUploadForm (param) {
      let formData = new FormData()
      formData.append('id', this.form.id)
      formData.append('file', param.file)
      const res = await uploadData(formData)
      if (res.status === 200) {
        this.$alert('成功导入数据', '成功', {
          confirmButtonText: '确定',
          callback: action => {
            location.reload()
          }
        })
      } else {
        this.$alert('导入失败,请检查数据源', '失败', {
          confirmButtonText: '确定',
          callback: action => {
            location.reload()
          }
        })
      }
    },
    onSubmit () {
      this.$refs.upload.submit()
    }
  }
}
</script>
<style lang="scss">
.box-card {
  width: 600px;
}
</style>
