<template>
    <!-- 登录 -->
          <div class="loginbBody">
            <div class="loginDiv">
              <div class="login-content">
                <h1 class="login-title">用户登录</h1>
                <el-form :model="user" label-width="100px"
                         :rules="rules" ref="loginForm">
                  <el-form-item label="用户名" prop="username">

                    <el-input style="width: 200px" type="text" v-model="user.username"
                              autocomplete="off" size="small"></el-input>
                  </el-form-item>
                  <el-form-item label="密码" prop="password">
                    <el-input style="width: 200px" type="password" v-model="user.password"
                              show-password autocomplete="off" size="small"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="login" plain>确 定</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>


<!--            <el-main>
                <div class="box">
                    <el-form
                            :model="user"
                            :rules="rules"
                            ref="user"
                            label-width="100px"
                            class="demo-ruleForm"
                    >
                        <el-form-item label="用户名" prop="username">
                            <el-input v-model="user.username"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input type="password" v-model="user.password"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="login">登录</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-main>-->
</template>

<script>
    import { login } from '../api/login'
    import md5 from "js-md5";  // 后端登录接口
    export default {
      data() {
            return {
                rules: {
                    username: [
                        { required: true, message: "请输入用户名", trigger: "blur" },
                        { min: 3, max: 20, message: "长度在 3 到 20 个字符", trigger: "blur" },
                    ],
                    password: [
                        { required: true, message: "请输入密码", trigger: "blur" },
                        { min: 3, max: 20, message: "长度在 3 到 20 个字符", trigger: "blur" },
                    ],
                },
                user:{
                    username:'',
                    password:''
                },
              userName:''// 用于存储从后台获取的token
            };
        },
        methods:{
            login(){
                // 登录接口
              const password = md5(this.user.password)
              console.log('md5:',password)
                login(this.user.username,password)
                    .then((res)=>{
                        if(res.data.code === 200){
                          localStorage.setItem('token', res.data.data.username);
                          if (res.data.data.username === 'export') {
                            this.$router.push('/edit/exportData')
                          } else {
                            this.$router.push('/')
                          }
                          this.$message({
                            message: '恭喜你，登录成功',
                            type: 'success'
                          });
                        }else{
                          localStorage.removeItem('token');
                          this.$message.error("用户名或密码错误");
                        }
                    })
                    .catch(err=>{
                        console.log(err);
                    })
            }
        },
    };
</script>
<style scoped >
.loginbBody {
  width: 100%;
  height: 100%;
  background-color: #0c317a;
}
.loginDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -250px;
  width: 450px;
  height: 330px;
  background: darkgrey;
  border-radius: 5%;

}
.login-title {
  margin: 20px 0;
  text-align: center;

}
.login-content {
  width: 400px;
  height: 250px;
  position: absolute;
  top: 25px;
  left: 25px;
}
</style>
